<template>
  <div class="accountAdd">
    <form-panel ref="formPanel" v-bind="submitConfig" :form="form" :submitBefore="submitBefore" @update="update">
      <!-- 解决浏览器自动填充账号密码的问题 -->
      <input type="text" autocomplete="off" style="display: none" />
      <input type="password" style="position: fixed; bottom: -99999px" />
      <el-form-item label="姓名" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]" prop="userName">
        <v-input placeholder="请输入姓名" v-model="form.userName"></v-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        :rules="[
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: validatePhone, trigger: 'blur' }
        ]"
        prop="mobileNum"
      >
        <v-input placeholder="请输入手机号" v-model="form.mobileNum" :maxlength="11" type="number"></v-input>
      </el-form-item>

      <el-form-item label="账号名" :rules="[{ required: true, message: '请输入账号名', trigger: 'blur' }]" prop="loginName">
        <v-input placeholder="请输入账号名" v-model="form.loginName"></v-input>
      </el-form-item>
      <el-form-item
        v-if="!onOff"
        label="密码"
        :rules="[
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            validator: validateNewPassword
          }
        ]"
        prop="loginPwd"
      >
        <v-input placeholder="请输入密码" type="password" v-model="form.loginPwd"></v-input>
      </el-form-item>
      <el-form-item
        v-if="!onOff"
        label="密码确认"
        :rules="[
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'change'
          },
          {
            validator: validateNewPasswordRepeat,
            trigger: 'change'
          }
        ]"
        prop="newPasswordRepeat"
      >
        <v-input placeholder="请确认密码" type="password" v-model="form.newPasswordRepeat"></v-input>
      </el-form-item>

      <el-form-item label="所属管理组" :rules="[{ required: true, message: '请选择所属管理组', trigger: 'change' }]" prop="manageGroupId">
        <select-tree
          v-loading="treeLoading"
          :value.sync="form.manageGroupId"
          :data.sync="treeSelectManageData"
          placeholder="所属管理组"
          :treeData="treeManageData"
          :treeProps="treeManageProps"
          :highlightCurrent="true"
          :filterNodeMethod="filterNodeMethod"
          :expand-on-click-node="false"
        />
      </el-form-item>

      <el-form-item label="绑定角色" prop="roleId" :rules="[{ required: true, message: '请绑定角色', trigger: 'change' }]">
        <select-tree
          :loading="treeRoleLoading"
          :value.sync="form.roleId"
          :data.sync="treeSelectData"
          placeholder="所属角色"
          :treeData="treeData"
          :treeProps="treeProps"
          :highlightCurrent="true"
          :filterNodeMethod="filterNodeMethod"
          :expand-on-click-node="false"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { mobileRegular } from '@/utils/regular';
import selectTree from '@/components/bussiness/select-tree/select-tree.vue';
import { addAccountUrl, accountDetailUrl, getManageGroupTreeUrl, roleListUrl, bindRoleUrl } from './api.js';

export default {
  name: 'accountAdd',
  components: {
    'select-tree': selectTree
  },
  data() {
    return {
      id: '',
      form: {
        id: '', // 数据唯一值
        userName: '', // 姓名（昵称）
        mobileNum: '', // 手机号
        loginName: '', // 登陆账户
        originPwd: '', //初始密码
        loginPwd: '', // 登陆密码
        newPasswordRepeat: '', // 确认密码
        manageGroupId: '', // 管理组id
        roleId: '', // 角色id
        userSource: 3, // 固定
        isRegister: 4 // 固定
      },
      /* 管理组树 */
      treeSelectManageData: {}, // 所选树节点的数据
      treeManageData: [], // 数据节点选项数据
      treeLoading: false,
      treeManageProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'children', // 必须
        label: 'name' // 必须
      },
      /* 角色树 */
      treeData: [],
      treeSelectData: {},
      treeRoleLoading: false,
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'children', // 必须
        label: 'roleName' // 必须
      },

      submitConfig: {
        queryUrl: accountDetailUrl,
        queryMethod: 'post',
        submitUrl: addAccountUrl
      },
      onOff: false // 是否编辑的第一次进入
    };
  },
  computed: {
    manageGroupId() {
      return this.form.manageGroupId;
    }
  },
  watch: {
    manageGroupId(val) {
      if (!this.onOff) {
        this.form.roleId = '';
        console.log(this.form, 'this.form');
        this.treeSelectData = {};
        this.treeData = [];
      }
      if (val) {
        this.getRoleTreeList();
      }
    }
  },
  mounted() {
    this.getTreeList();
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ userId: id });
    }
    this.onOff = id !== undefined;
    this.$setBreadList(id ? '编辑' : '新增');
  },
  methods: {
    submitBefore() {
      if (this.onOff) {
        if (this.form.originPwd == this.form.loginPwd) {
          return {
            id: this.form.id, // 数据唯一值
            userName: this.form.userName, // 姓名（昵称）
            mobileNum: this.form.mobileNum, // 手机号
            loginName: this.form.loginName, // 登陆账户
            manageGroupId: this.form.manageGroupId, // 管理组id
            roleId: this.form.roleId, // 角色id
            userSource: 3,
            isRegister: 4
          };
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    update(data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key];
      });
      this.form.originPwd = this.form.loginPwd;
      this.form.newPasswordRepeat = this.form.loginPwd;
      this.form.roleId = data.roleId;
    },
    /* 验证及查询方法 */
    mobileValid(value) {
      return value.replace(/\D/g, '');
    },
    validateNewPassword(rule, value, callback) {
      if (!value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/)) {
        callback(new Error('需要至少8位包含英文、字符、数字的密码'));
      } else {
        callback();
      }
    },
    validateNewPasswordRepeat(rule, value, callback) {
      if (value !== this.form.loginPwd) {
        callback(new Error('两次密码必须一致'));
      } else {
        callback();
      }
    },
    validatePhone(rule, value, callback) {
      let regExp = mobileRegular;
      if (!regExp.test(value)) {
        callback(new Error('您填写手机号码格式不正确'));
      } else {
        callback();
      }
    },
    filterNodeMethod(value, data) {
      // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      let result = data[this.treeProps.label] === undefined ? data[this.treeManageProps.label] : data[this.treeProps.label];
      return result.indexOf(value) !== -1;
    },

    // 获取树节点请求
    async getTreeList() {
      // 获取树节点数据
      this.treeLoading = true;
      this.treeManageData = [];
      const res = await this.$axios.get(getManageGroupTreeUrl);
      if (res.code == 200 && res.success) {
        this.treeManageData = res.data || [];
      }
      this.treeLoading = false;
    },
    // 获取角色树节点请求
    async getRoleTreeList() {
      this.treeRoleLoading = true;
      this.treeData = [];
      const res = await this.$axios.get(roleListUrl, {
        params: {
          manageGroupId: this.manageGroupId
        }
      });
      if (res.code == 200 && res.success) {
        this.treeData = res.data || [];
      }
      this.treeRoleLoading = false;
    },
    // 请求成功后调接口--作用未知
    async submitSuccess(data) {
      // let params = {
      //   userIds: data,
      //   roleIds: this.form.roleIds,
      // };
      // let res = await this.$axios.post(bindRoleUrl, params);
      // if (res.code == 200 && res.success) {
      //   return true;
      // } else {
      //   return false;
      // }
    }
  }
};
</script>
<style lang="less" scoped>
.accountAdd {
  height: 100%;
}
</style>
